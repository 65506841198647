import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const GlobalBackButtonBlocker: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      console.log("Back button pressed, blocking navigation"); // Debug log
      event.preventDefault();
      navigate(location.pathname); // Stay on the same page
    };

    // Push initial state into history
    window.history.pushState(null, "", window.location.href);

    // Add event listener for popstate
    window.addEventListener("popstate", handleBackButton);

    return () => {
      // Cleanup listener
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate, location.pathname]);

  return null; // No UI for this component
};

export default GlobalBackButtonBlocker;
